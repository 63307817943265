import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import ScriptLoader from 'yoda-core-components/lib/helpers/ScriptLoader/ScriptLoader';
import { detectSafari } from 'yoda-core-components/lib/helpers/Utils/Utils';
import {
    selectPreferences,
    selectFeatureFlags,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import { requestShopTheLookPricing } from 'yoda-site-components/lib/actions/ShopTheLookAction';
import {
    miniPDPOnShopTheLookOpen,
    miniPDPOnClose,
} from 'yoda-site-components/lib/actions/MiniPDPAction';
import ProductConstants from 'yoda-site-components/lib/common/ProductConstants';
import { decimalRounding } from 'yoda-site-components/lib/helpers/Pricing/Pricing';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import {
    shopThisLookClickEvent,
    shopTheLookMiniPDPClickEvent,
} from 'yoda-site-components/lib/actions/AnalyticsAction';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import User from 'yoda-site-components/lib/helpers/User/User';
import { ACCOUNT_ID } from 'yoda-site-components/lib/common/Constants';
import * as styles from './FeatureShops.css';
import marketingAction from '../../../actions/MarketingAction';

const cx = classNames.bind(styles);

export class FeatureShops extends Component {
    static propTypes = {
        location: PropTypes.objectOf(PropTypes.object),
        productPrices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        actions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        preferences: PropTypes.oneOfType([PropTypes.object]),
        featureFlags: PropTypes.oneOfType([PropTypes.object]),
        pageNameChange: PropTypes.func,
        miniPDP: PropTypes.oneOfType([PropTypes.object]),
    };

    static defaultProps = {
        location: {},
        productPrices: [],
        actions: {},
        preferences: {},
        featureFlags: {},
        pageNameChange: null,
        miniPDP: {},
    };

    constructor(props) {
        super(props);
        this.styliticsWidgetObj = null;
        this.state = {
            scriptLoaded: false,
            outfitsFromWidget: [],
            initialLoadEmptyOutfits: false,
        };
        this.stylisticsRef = React.createRef();
    }

    componentDidMount() {
        this.loadScript();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { actions } = this.props;

        if (nextProps?.miniPDP && nextProps?.miniPDP?.miniPDPSTL === 'open') {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                detectSafari()
                    ? /* istanbul ignore next */ (window.history.scrollRestoration = 'manual')
                    : window.history.go(1);
                actions.miniPDPOnClose();
            };
        }
        if (nextProps?.miniPDP && nextProps?.miniPDP?.miniPDPSTL === 'close') {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.go(-1);
            };
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { productPrices } = this.props;
        const { outfitsFromWidget } = this.state;

        if (nextState.outfitsFromWidget !== outfitsFromWidget) {
            return true;
        }

        if (nextProps.productPrices !== productPrices) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { productPrices } = this.props;
        /* istanbul ignore next */
        if (prevProps.productPrices !== productPrices) {
            this.getPriceData();
        }
    }

    loadScript = () => {
        const { preferences } = this.props;
        const stylisticsURL = preferences.featureShopURL;
        const stylisticsProps = {
            src: stylisticsURL,
            async: true,
            defer: false,
            onSuccess: /* istanbul ignore next */ () => {
                this.initiateFeatureShop();
            },
        };
        ScriptLoader.load(stylisticsProps);
        this.setState({
            scriptLoaded: true,
        });
    };

    getOptionsForFeatureShop = () => {
        const profileID = User?.isUserLoggedIn(true) ? TokenProvider.get(ACCOUNT_ID) : 'NA';
        const { location } = this.props;
        const params = location.pathname;
        const featureShopSlug = params?.split(ProductConstants.FEATURE_SHOP);
        const opts = {
            'featured-shops-slug': featureShopSlug && featureShopSlug[1],
            'stylitics-target-id': 'stylitics-featured-shops-target',
            'look-card-type': 'moodboard',
            'show-arrows-on-touch?': true,
            'next-item-peekable-factor': 0.25,
            customer: {
                profileId: profileID,
            },
            routing: {
                'use-fragment': false,
                'use-head-title': false,
            },
        };
        return opts;
    };

    constructPPid = (bundleOutfits) => {
        const productIds = [];
        if (bundleOutfits) {
            bundleOutfits.forEach((subelement) => {
                const id = get(subelement, 'product_id', '');
                productIds.push(id);
            });
        }
        return productIds;
    };

    /* istanbul ignore next */
    initiateFeatureShop = () => {
        const { scriptLoaded } = this.state;
        const { featureFlags } = this.props;
        const arr = [];
        /* istanbul ignore next */
        if (scriptLoaded) {
            const opts = this.getOptionsForFeatureShop();
            const myShop = stylitics.featured_shops.core.start(opts);
            if (!featureFlags.disableStyliticsColor && this.stylisticsRef) {
                myShop.on('item-mount', ({ item, element }) => {
                    // adds treatment only to Featured Items
                    if (
                        item?.promotions.includes('with_code') &&
                        item?.price > item?.sale_price &&
                        element?.tagName === 'DIV'
                    ) {
                        const text = document.createElement('span');
                        text.classList.add(styles.tRed);
                        text.style.marginLeft = '2px';
                        text.innerHTML = ' with code';
                        const priceContainer = element.querySelector(
                            '.stylitics-items-gallery-item-price'
                        );
                        priceContainer
                            .querySelector('.stylitics-item-sale-price')
                            .classList.add(styles.tRed);
                        priceContainer.append(text);
                    }
                });
            }

            myShop.on('bundle-mount', (data) => {
                if (Object.keys(data).length > 0) {
                    const bundleOutfits = get(data, 'look', {});
                    arr.push(bundleOutfits);
                    this.setState({ outfitsFromWidget: arr });
                }
            });
            featureFlags.enableFeatureShopsMiniPDP &&
                myShop.onItemClick((item) => {
                    this.onFeatureShopItemClick(item);
                });
            myShop.on('outfit-click', this.myOutfitViewListener);
            myShop.on('shop-view', this.myShopViewListener);
        }
    };

    onFeatureShopItemClick = (ppId) => {
        const { actions } = this.props;
        /* istanbul ignore next */
        const skuData = get(ppId, 'remote-id', '');
        const outfitID = get(ppId, 'outfit-id', '');
        const featureItemID = get(ppId, 'id', '');
        const skuID = get(ppId, 'other-client-item-ids.sku-nbrs', '');
        /* istanbul ignore next */
        if (!isEmpty(skuData)) {
            const options = {
                ppId: skuData?.split('_')[0],
                skuId: skuID && skuID[0],
            };
            const customEvent = document.createEvent('CustomEvent');
            actions.miniPDPOnShopTheLookOpen();
            actions.shopTheLookMiniPDPClickEvent({
                shopTheLookID: outfitID || featureItemID || '',
                shopTheLookPPID: skuData?.split('_')[0],
                moduleName: outfitID ? 'featured looks' : 'featured items',
            });
            customEvent.initCustomEvent('miniPDP.open', true, true, options);
            document.dispatchEvent(customEvent);
        }
    };

    myOutfitViewListener = (e) => {
        const { actions } = this.props;
        const shopTheLookID = get(e, 'outfit-id', '');
        const data = this.state.outfitsFromWidget.filter((data) => data.id === shopTheLookID);
        shopTheLookID &&
            actions.shopThisLookClickEvent({ shopTheLookID, moduleName: 'featured looks' });
        const bundleOutfits = data[0]?.items;
        actions.requestShopTheLookPricing(this.constructPPid(bundleOutfits));
    };

    myShopViewListener = () => {
        const { pageNameChange } = this.props;
        const shopBlock = this.stylisticsRef?.current?.querySelectorAll(
            `.stylitics-carousel-item-active`
        );
        const pageNameData = shopBlock && shopBlock[0]?.ariaLabel;
        /* istanbul ignore next */
        pageNameData && pageNameChange(pageNameData);
    };

    getPriceData = () => {
        const { productPrices } = this.props;
        let pricingBlockData = '';
        /* istanbul ignore next */
        if (productPrices && productPrices.length) {
            productPrices[0].allProducts.forEach((price) => {
                if (this.stylisticsRef) {
                    const parentElement = this.stylisticsRef.current.querySelectorAll(
                        `[aria-label*=${JSON.stringify(price.name)}]`
                    );
                    const priceElement = parentElement && parentElement[0]?.parentElement;
                    const salePrice =
                        priceElement &&
                        priceElement.querySelectorAll(
                            `.stylitics-item-price-and-cta .stylitics-item-two-prices`
                        );
                    const itemPrice =
                        priceElement &&
                        priceElement.querySelectorAll(`.stylitics-outfit-list-item-regular-price`);
                    const priceData = get(price, 'pricing.root', {});
                    if (priceData && Object.keys(priceData).length > 0) {
                        const isCoupon = get(price, 'pricing.root.couponInfo', []);
                        const isAmount = get(price, 'pricing.root.amounts', []);
                        const amountType = get(isAmount[1], 'type', '');
                        const couponOrType = `<span class=${styles.itemPriceData}>${
                            isCoupon && isCoupon.length
                                ? ProductConstants.CODE
                                : isAmount &&
                                  isAmount.length &&
                                  amountType !== ProductConstants.DEFAULT
                                ? amountType.toLowerCase()
                                : ''
                        }</span>`;
                        if (isCoupon && isCoupon.length) {
                            pricingBlockData = `<span class=${dt([
                                'text-brand-red',
                            ])}> $${decimalRounding(
                                trim(get(isCoupon[0], 'amount.min', ''))
                            )} ${couponOrType} </span>`;
                        } else {
                            pricingBlockData =
                                isAmount &&
                                isAmount.length &&
                                isAmount[1].min &&
                                isAmount[1].max &&
                                isAmount[1].min !== isAmount[1].max
                                    ? `$${decimalRounding(
                                          trim(isAmount[1].min)
                                      )} - $${decimalRounding(trim(isAmount[1].max))}`
                                    : `$${decimalRounding(trim(isAmount[1].min))} ${couponOrType}`;
                        }
                        const priceBlock =
                            salePrice && salePrice.length
                                ? salePrice
                                : itemPrice && itemPrice.length && itemPrice;
                        const pricingData = `<span class=${dt([
                            'font-open-sans-bold',
                        ])}> ${pricingBlockData} </span>`;
                        if (priceBlock && priceBlock.length) {
                            priceBlock.forEach((element) => {
                                element.innerHTML = pricingData;
                            });
                        }
                    }
                }
            });
        }
    };

    stylisticsMarkup = () => {
        const stylistics = `<style>
        .stylitics-look-cta {
            z-index: 7 !important;
        }
        </style>`;
        return stylistics;
    };

    loadContent = () => {
        const FeatureShopBlock = (
            <div>
                <h4
                    className={dt([
                        'text-center',
                        'font-open-sans-semibold',
                        '!leading-8',
                        'lg:text-xLg',
                        'sm:text-lg',
                        'pb-3',
                    ])}
                >
                    Feature Shops
                </h4>
                <div
                    id="stylitics-featured-shops-target"
                    className={`${cx('FeatureShopContainer')} ${dt([
                        'text-center',
                        'font-open-sans-medium',
                        '!text-base',
                        'leading-7',
                        'mb-10',
                    ])}`}
                    dangerouslySetInnerHTML={{ __html: this.stylisticsMarkup() }}
                    ref={this.stylisticsRef}
                />
            </div>
        );
        return FeatureShopBlock;
    };

    render() {
        return <div>{this.loadContent()}</div>;
    }
}

const mapStateToProps = (state) => ({
    preferences: selectPreferences(state),
    featureFlags: selectFeatureFlags(state),
    productPrices: state.shopTheLook ? state.shopTheLook.productPrices : {},
    miniPDP: state.miniPDP ? state.miniPDP : {},
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...marketingAction,
            requestShopTheLookPricing,
            miniPDPOnShopTheLookOpen,
            shopThisLookClickEvent,
            shopTheLookMiniPDPClickEvent,
            miniPDPOnClose,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureShops);
