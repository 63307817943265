import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import RedirectAction from 'yoda-app-shell/lib/action/RedirectAction';
import IrisPage from 'yoda-site-components/lib/components/IrisPage/IrisPage';
import IrisActions from 'yoda-site-components/lib/actions/IrisAction';
import CMPageLazy from 'yoda-site-components/lib/components/CoreMediaPage/CMPageLazy';
import CMPageAction from 'yoda-site-components/lib/actions/CMPageAction';
import irisComponentsForMarketing from 'yoda-site-components/lib/iris/components/forMarketing';
import Layout from 'yoda-site-components/lib/components/Layout/Layout';
import isEmpty from 'lodash/isEmpty';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import ProductConstants from 'yoda-site-components/lib/common/ProductConstants';
import renderMiniPDP from '../../../helper/renderMiniPDP';
import marketingAction from '../../../actions/MarketingAction';
import FeatureShops from './FeatureShops';

/* istanbul ignore next */
class Marketing extends PureComponent {
    static defaultProps = {
        location: {},
        actions: {},
        irisData: {},
        cmData: {},
        cmSwitchWidgetSelectedValue: '',
        irisFallbackPreferences: {},
        cmFallbackPreferences: {},
        enableCmPage: false,
        irisFallbackEnabled: false,
        cmFallbackEnabled: false,
        disableIrisCall: false,
        isIrisFallBackEnabled: false,
        isPreview: false,
        enableFeatureShops: false,
        enableMiniPDPFeature: true,
    };

    static propTypes = {
        location: PropTypes.objectOf(PropTypes.object),
        actions: PropTypes.objectOf(PropTypes.func),
        irisData: PropTypes.objectOf(PropTypes.object),
        cmData: PropTypes.objectOf(PropTypes.object),
        irisFallbackPreferences: PropTypes.arrayOf(PropTypes.object),
        enableCmPage: PropTypes.bool,
        isPreview: PropTypes.bool,
        cmFallbackPreferences: PropTypes.arrayOf(PropTypes.object),
        irisFallbackEnabled: PropTypes.arrayOf(PropTypes.bool),
        cmFallbackEnabled: PropTypes.arrayOf(PropTypes.bool),
        disableIrisCall: PropTypes.arrayOf(PropTypes.bool),
        isIrisFallBackEnabled: PropTypes.bool,
        cmSwitchWidgetSelectedValue: PropTypes.string,
        enableFeatureShops: PropTypes.bool,
        enableMiniPDPFeature: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            featureShopPageName: '',
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        this.triggerAnalyticsData();
    }

    componentDidMount() {
        const {
            irisFallbackPreferences,
            cmFallbackPreferences,
            irisFallbackEnabled,
            cmFallbackEnabled,
            location,
            disableIrisCall,
            actions,
            enableCmPage,
            history,
            cmSwitchWidgetSelectedValue,
            isPreview,
            isIrisFallBackEnabled,
            enableCMSwitchWidget,
        } = this.props;
        const params = location.pathname;
        const isCMTestEnabled = JSON.parse(new URLSearchParams(location?.search).get('cmTest'));
        const irisEvergreenload = LocalStorage.getData('irisEvergreenload', true);

        const isCMPageEnabled =
            isPreview && enableCMSwitchWidget
                ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                  !isIrisFallBackEnabled &&
                  (enableCmPage || isCMTestEnabled) &&
                  isCMTestEnabled !== false &&
                  !irisEvergreenload
                : !isIrisFallBackEnabled &&
                  (enableCmPage || isCMTestEnabled) &&
                  isCMTestEnabled !== false &&
                  !irisEvergreenload;

        window.triggerShippingHandling = () => {
            if (isCMPageEnabled) {
                actions.getCMPageTemplateData(
                    params,
                    true,
                    undefined,
                    cmFallbackEnabled,
                    cmFallbackPreferences,
                    RedirectAction.setRedirectUrl,
                    location,
                    history
                );
            } else {
                (!enableCmPage || isIrisFallBackEnabled) &&
                    actions.getIrisTemplateData(
                        params,
                        true,
                        undefined,
                        irisFallbackEnabled,
                        irisFallbackPreferences,
                        RedirectAction.setRedirectUrl,
                        disableIrisCall
                    );
            }
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isIrisFallBackEnabled) {
            return true;
        }
        if (nextProps.cmSwitchWidgetSelectedValue !== this.props?.cmSwitchWidgetSelectedValue) {
            return true;
        }

        if (nextState.featureShopPageName !== this.state?.featureShopPageName) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.irisData !== this.props.irisData ||
            prevState.featureShopPageName !== this.state?.featureShopPageName
        ) {
            this.triggerAnalyticsData();
        }
    }

    pageNameAttribute = (data) => {
        this.setState({ featureShopPageName: data });
    };

    triggerAnalyticsData() {
        const {
            enableCmPage,
            location,
            isIrisFallBackEnabled,
            cmSwitchWidgetSelectedValue,
            isPreview,
            enableCMSwitchWidget,
            enableFeatureShops,
        } = this.props;
        // IRIS digital marketing action call
        const params = location.pathname;
        const isCMTestEnabled = JSON.parse(new URLSearchParams(location?.search).get('cmTest'));
        const irisEvergreenload = LocalStorage.getData('irisEvergreenload', true);
        const featureShopParam = params?.includes(ProductConstants.FEATURE_SHOP);
        const isFeatureShopEligible = enableFeatureShops && featureShopParam;
        const featureShopSlug = params?.split(ProductConstants.FEATURE_SHOP);
        // IRIS digital marketing action call

        if (!__SERVER__ && !isEmpty(this.props.irisData)) {
            const {
                irisData: { analytics },
                actions,
            } = this.props;
            if (analytics) {
                const { pageType, pageName } = analytics;
                /* istanbul ignore next */
                actions.getMarketingLoaded(pageName, pageType);
            }
        } else if (isFeatureShopEligible) {
            const { actions } = this.props;
            const pageType = 'promotions';
            const pageName = `featureshop:${featureShopSlug && featureShopSlug[1]}:${
                this.state.featureShopPageName
            }`;
            actions.getMarketingLoaded(pageName, pageType);
        } else {
            // CM digitalData Marketing
            const isCMPageEnabled =
                isPreview && enableCMSwitchWidget
                    ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                      ((!isIrisFallBackEnabled && enableCmPage) || isCMTestEnabled) &&
                      isCMTestEnabled !== false &&
                      !irisEvergreenload
                    : ((!isIrisFallBackEnabled && enableCmPage) || isCMTestEnabled) &&
                      isCMTestEnabled !== false &&
                      !irisEvergreenload;

            if (!__SERVER__ && isCMPageEnabled) {
                const { cmData, actions } = this.props;
                const cmAnalyticsData = cmData?.page?.analytics;
                if (cmAnalyticsData) {
                    const { pageType, pageName } = cmAnalyticsData;
                    /* istanbul ignore next */
                    actions.getMarketingLoaded(pageName, pageType);
                }
            }
        }
    }

    render() {
        /* IRIS endpoint to hit/relative URL  */
        const {
            irisFallbackPreferences,
            cmFallbackPreferences,
            location,
            enableCmPage,
            enableFeatureShops,
            history,
            isIrisFallBackEnabled,
            irisFallbackEnabled,
            cmSwitchWidgetSelectedValue,
            isPreview,
            enableCMSwitchWidget,
            cmData,
            enableMiniPDPFeature,
        } = this.props;
        const params = location.pathname;
        const isCMTestEnabled = JSON.parse(new URLSearchParams(location?.search).get('cmTest'));
        const renderMiniPDPComponent = enableMiniPDPFeature ? renderMiniPDP() : null;
        const irisEvergreenload = LocalStorage.getData('irisEvergreenload', true);
        const cmEvergreenload = LocalStorage.getData('cmEvergreenload', true);
        const featureShopParam = params?.includes(ProductConstants.FEATURE_SHOP);
        const isFeatureShopEligible = enableFeatureShops && featureShopParam;

        const isCMPageEnabled =
            isPreview && enableCMSwitchWidget
                ? !(cmSwitchWidgetSelectedValue === 'IRIS') &&
                  !isIrisFallBackEnabled &&
                  (enableCmPage || isCMTestEnabled) &&
                  isCMTestEnabled !== false &&
                  !irisEvergreenload
                : !isIrisFallBackEnabled &&
                  (enableCmPage || isCMTestEnabled) &&
                  isCMTestEnabled !== false &&
                  !irisEvergreenload;

        const cmRowData = get(cmData, 'page.grid.rows', []);
        const checkCMDataAvailable = (cmRowData) => {
            const isPlacementDataEmpty = cmRowData
                ?.map((placements) => {
                    return placements?.placements[0];
                })
                .filter((placement) => placement?.name === 'S1' || placement?.name === 'S2')
                .every((placement) => placement?.items?.length === 0);
            return isPlacementDataEmpty;
        };

        if (!isPreview) {
            LocalStorage.removeData('irisEvergreenload', true);
            LocalStorage.removeData('cmEvergreenload', true);
        }

        const isCMComponentDataEmpty =
            cmRowData?.length > 0 ? checkCMDataAvailable(cmRowData) : false;
        return (
            <Layout>
                {!isFeatureShopEligible ? (
                    (isCMPageEnabled ||
                        cmSwitchWidgetSelectedValue === 'core Media' ||
                        cmEvergreenload) &&
                    !isCMComponentDataEmpty ? (
                        <CMPageLazy
                            params={params}
                            pageType="MARKETING"
                            cmFallbackPreferences={cmFallbackPreferences}
                            redirectAction={RedirectAction.setRedirectUrl}
                            aggregateAPI
                            isCMPageEnabled={
                                isCMPageEnabled || cmSwitchWidgetSelectedValue === 'core Media'
                            }
                            location={location}
                            history={history}
                        />
                    ) : (
                        (!enableCmPage ||
                            isIrisFallBackEnabled ||
                            cmSwitchWidgetSelectedValue === 'IRIS' ||
                            (isCMPageEnabled === false && cmSwitchWidgetSelectedValue === '')) && (
                            <IrisPage
                                params={params}
                                pageType="MARKETING"
                                irisFallbackEnabled={irisFallbackEnabled}
                                irisComponents={irisComponentsForMarketing}
                                irisFallbackPreferences={irisFallbackPreferences}
                                redirectAction={RedirectAction.setRedirectUrl}
                                aggregateAPI
                                location={location}
                                isFallingBackAfterCMFailed={isIrisFallBackEnabled}
                            />
                        )
                    )
                ) : (
                    <FeatureShops location={location} pageNameChange={this.pageNameAttribute} />
                )}
                {renderMiniPDPComponent}
            </Layout>
        );
    }
}

/* istanbul ignore next */
const mapStateToProps = ({ irisData, cmData, cmSwitchWidgetData, context }) => ({
    irisData,
    cmData,
    cmSwitchWidgetSelectedValue: get(cmSwitchWidgetData, 'cmSwitchWidgetSelectedValue', ''),
    irisFallbackPreferences: get(context, 'preferences.irisfallbackPreferences', {}),
    cmFallbackPreferences: get(context, 'preferences.irisfallbackPreferences', {}),
    irisFallbackEnabled: get(context, 'featureFlags.irisFallbackEnabled', false),
    cmFallbackEnabled: get(context, 'featureFlags.irisFallbackEnabled', false),
    disableIrisCall: get(context, 'featureFlags.disableIrisCall', false),
    cmPageinclusionList: get(context, 'preferences.cmPageInclusionList', []),
    isPreview: get(context, 'isPreview', false),
    enableCmPage: get(context, 'featureFlags.enableCmPage', false),
    enableFeatureShops: get(context, 'featureFlags.enableFeatureShops', false),
    isIrisFallBackEnabled: get(cmData, 'isIrisFallBackEnabled', false),
    enableCMSwitchWidget: get(context, 'featureFlags.enableCMSwitchWidget', false),
    enableMiniPDPFeature: get(context, 'featureFlags.enableMiniPDPFeature', true),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...marketingAction,
            ...IrisActions,
            ...CMPageAction,
        },
        dispatch
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Marketing));
