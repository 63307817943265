import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';
import cookie from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import constants from './Marketing.config';

export const getURLParameterBykey = (key, url = window.location.href) => {
    // TODO - this will moved to Util function later
    let name = key;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ''));
};

// Page is boolean value, if true, we load page level and we don't set apply method
const createCardRewards = (adjustment, method, bem, page) => {
    const marketingRewardItem = {};
    const marketingCode = `${adjustment.value}-${constants.TOF}`;
    const description = `${adjustment.description}`;
    marketingRewardItem.marketingCode = marketingCode.toLowerCase();
    marketingRewardItem.marketingEligible = adjustment.amount ? constants.YES : constants.NO;
    marketingRewardItem.marketingType = description.toLowerCase();
    marketingRewardItem.marketingAmount = `${adjustment.amount}`;
    /* istanbul ignore else */
    if (!page) {
        marketingRewardItem.marketingApplyMethod = `${method}`.toLowerCase();
        marketingRewardItem.bem = bem;
    }
    marketingRewardItem.rewardRedemptionID = constants.EMPTY;
    marketingRewardItem.rewardValid = constants.EMPTY;
    marketingRewardItem.rewardType = constants.EMPTY;
    marketingRewardItem.rewardAmount = constants.EMPTY;
    marketingRewardItem.rewardApplyMethod = constants.EMPTY;
    return marketingRewardItem;
};

export const getShopThisLookOnClick = (payload) => {
    return { shopTheLookID: payload.shopTheLookID, moduleName: payload.moduleName };
};

export const getShopTheLookMiniPDPOnClick = (payload) => {
    return {
        shopTheLookID: payload.shopTheLookID,
        shopTheLookPPID: payload.shopTheLookPPID,
        moduleName: payload.moduleName,
    };
};
class MarketingActionFilter extends BaseActionFilter {
    static getMarketingpageDL(store, payload) {
        const { pageType = '', pageName = '' } = payload;
        const stateObj = store.getState();
        const { isMobile, isDesktop } = stateObj.context.deviceType;
        let itemsInSaveForLater = 0;
        let expressCheckoutEnabled = 'no';
        const mobileDeviceType = isMobile ? 'mobile' : 'tablet';
        const deviceType = isDesktop ? 'desktop' : mobileDeviceType;
        itemsInSaveForLater = cookie.load('DP_USER_FAVCOUNT');
        const profileAttr = cookie.load('omni_profileAttr') || '';
        const profileAttrArray = profileAttr.split('|');
        if (profileAttrArray && profileAttrArray.length >= 2) {
            /* istanbul ignore next */
            expressCheckoutEnabled = profileAttrArray[0] === 'Y' ? 'yes' : 'no';
        }

        const marketingRewardsInfo = [];
        if (stateObj.appliedAdjustments.length > 0) {
            const adjustment = stateObj.appliedAdjustments[0];
            const result = createCardRewards(adjustment, '', true);
            marketingRewardsInfo.push(result);
        }

        const marketingDL = {
            page: {
                category: {
                    subCategory2: '',
                    subCategory1: '',
                    primaryCategory: constants.siteSection.MARKETING,
                    department: constants.siteSection.MARKETING,
                },
                pageInfo: {
                    pageType, // home | department | category | gallery | search | pdp | customer service | promotion
                    pageName,
                    siteSection: constants.siteSection.MARKETING, // home | men | women | for the home | search | gift-card
                    searchRedirect: getURLParameterBykey('redirectTerm') || '',
                    deviceType, // referringURL: document.referrer,
                    releaseVersion: stateObj.context.__RELEASEVERSION__, // eslint-disable-line
                    version: 'yoda marketing 1',
                },
            },
            user: {
                // this will be common for all Micro pages- But we reading info from store, should be there in each micro site
                profile: {
                    profileInfo: {
                        itemsInSaveForLater,
                        expressCheckoutEnabled,
                    },
                },
            },
            cart: {
                marketingRewardsInfo,
            },
        };
        return marketingDL;
    }
}

export default MarketingActionFilter;
