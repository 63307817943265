import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import NotFound from 'yoda-app-shell/lib/components/NotFound/NotFound';
import PersonalizedContentService from 'yoda-site-components/lib/components/PersonalizedContentService/PersonalizedContentService';
import Marketing from './containers/Pages/Marketing/Marketing';
import BarberyAndMeevo from './containers/Pages/BarberyAndMeevo/BarberyAndMeevo';

export default function App() {
    return (
        <>
            <Switch>
                <Route path="/m/yoda-m2-page/N-mpi6e5" component={Marketing} />
                <Route path="/m/appointments/style-substance" component={BarberyAndMeevo} />
                <Route path="/m/appointments/barbery" component={BarberyAndMeevo} />
                <Route path="/m/personalizedContent" component={PersonalizedContentService} />
                <Redirect from="/marketing" to="m/yoda-m2-page/N-mpi6e5" />
                <Redirect from="/qa*-marketing" to="m/yoda-m2-page/N-mpi6e5" />
                {/* <Redirect from="/marketings" to="m/jcpenney-marketings/N-mpi6e5" />
                    <Redirect from="/m(/:pname)/N-mpi6e5(*)" to="m/jcpenney-marketings/N-mpi6e5(*)" />
                    <Redirect from="/m/:pname(/)" to="m/jcpenney-marketings/N-mpi6e5" /> */}
                <Route path="/" component={Marketing} />
                <Route path="/*" component={Marketing} />

                {/* when none of the above match, <NoMatch> will be rendered */}
                <Route component={NotFound} />
            </Switch>
        </>
    );
}
