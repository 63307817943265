/**
 * List of constants required for ensighten tagging, we will be using on page level components to update jcpDl object
 */
const constants = {
    siteSection: {
        MARKETING: 'promotion',
    },
    YES: 'yes',
    NO: 'no',
    EMPTY: '',
    TOF: 'tof',
};

export default constants;
