import { MARKETING_PAGE_LOAD } from '../actionTypes/MarketingActionTypes';

/* istanbul ignore next */
const getMarketingLoaded = (pageName, pageType) => ({
    type: MARKETING_PAGE_LOAD,
    payload: { pageName, pageType },
});

export default {
    getMarketingLoaded,
};
