import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Button from 'yoda-core-components/lib/components/Button';
import List from 'yoda-core-components/lib/components/List/List';
import Layout from 'yoda-site-components/lib/components/Layout/Layout';
import User from 'yoda-site-components/lib/helpers/User/User';
import * as sliderActions from 'yoda-site-components/lib/actions/AccountAction';
import * as appointmentActions from 'yoda-site-components/lib/actions/AppointmentActions';
import { selectAppointmentDetailsById } from 'yoda-site-components/lib/selectors/appointmentSelector';

import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';

export class BarberyAndMeevo extends Component {
    static defaultProps = {
        config: {},
        linkData: {},
        authenticationSuccess: false,
        barberyService: {},
        stores: [],
        meevoService: '',
        imageContent: {},
        meevoStores: [],
        requestUrl: '',
    };

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        sliderAction: PropTypes.objectOf(PropTypes.func).isRequired,
        linkData: PropTypes.oneOfType([PropTypes.object]),
        config: PropTypes.oneOfType([PropTypes.object]),
        authenticationSuccess: PropTypes.bool,
        barberyService: PropTypes.oneOfType([PropTypes.object]),
        stores: PropTypes.array,
        meevoService: PropTypes.string,
        imageContent: PropTypes.oneOfType([PropTypes.object]),
        meevoStores: PropTypes.array,
        requestUrl: PropTypes.string,
    };

    constructor() {
        super();

        this.state = {
            isLoading: false,
            storeId: '',
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { authenticationSuccess } = this.props;
        const { isLoading, storeId } = this.state;
        const isDataLoaded = !nextProps.linkData.isLoading; // && (isLoading !== nextProps.linkData.isLoading);
        if (isDataLoaded && isLoading) {
            this.setState({ isLoading: false });
        } else if (
            this.didItriggerAuthentication &&
            nextProps.authenticationSuccess &&
            nextProps.authenticationSuccess !== authenticationSuccess
        ) {
            // recall action
            this.onScheduleLink(storeId);
            // reset flag
            this.didItriggerAuthentication = false;
        }
    }

    isBarberyFlow = () => {
        const { requestUrl } = this.props;
        let isBarbery = false;
        isBarbery = requestUrl.match('/barbery')?.[0] === '/barbery';
        return isBarbery;
    };

    onScheduleLink = (storeID) => {
        const { sliderAction } = this.props;
        const { storeId } = this.state;
        let storeToLoad = storeID || '';
        if (storeID) {
            this.setState({
                storeId: storeID,
            });
        } else {
            storeToLoad = storeId || '';
        }
        const { barberyService, meevoService, actions } = this.props;
        const { service } = barberyService;
        const isMeevoAndBarbery = this.isBarberyFlow() ? service : meevoService;

        /* istanbul ignore next */
        if (User.isUserLoggedIn()) {
            this.setState({ isLoading: true }, () =>
                actions.getAppointmentLink(this.getServiceKey(isMeevoAndBarbery), storeToLoad)
            );
        } else {
            sliderAction.openSignInSlider();
            this.didItriggerAuthentication = true;
        }
    };

    getServiceKey = (serviceName) => {
        const {
            config: { exceptionalMapping },
        } = this.props;
        // for getting exceptional service name's key
        // to pass to account api
        return exceptionalMapping[serviceName] ? exceptionalMapping[serviceName] : serviceName;
    };

    renderStores = (store) => {
        const { linkData, config } = this.props;
        const { isLoading } = this.state;
        const { id, title } = store;

        return (
            <Button
                className={dt(['w-full'])}
                buttonType="Primary"
                size="Lg"
                onClick={() => this.onScheduleLink(id)}
            >
                <div>{title}</div>
                {/* Error Scenerio */}
                {!isLoading &&
                    !isEmpty(linkData.error) &&
                    this.renderError(get(config, 'serviceError', 'Error! Try Again'))}
            </Button>
        );
    };

    renderError = (errMsg) => <div>{errMsg}</div>;

    render() {
        const { stores, imageContent, meevoStores } = this.props;

        const { url, title, alt, labelText } = imageContent || {};

        const meevoImageContentBlockStyle = dt(['w-[60%]', 'my-0', 'mx-auto', 'smOnly:w-full']);
        const imageContentBlockStyle = dt(['md:w-[60%]', 'my-0', 'mx-auto', 'smOnly:w-full']);
        const imageContentStyle = dt(['w-full', 'block']);
        const labelTextStyle = dt(['text-2xlarge', 'text-gray-70', 'mb-8', 'text-center']);
        const buttonDivStyle = dt(['smMd:w-[calc(100%-48px)]', 'text-center']);

        const meevoStoreListStyle = dt([
            'lg:w-[75%]',
            'md:mb-[92px]',
            'md:w-full',
            'mt-0',
            'mx-auto',
            'sm:mb-[45px]',
        ]);
        const storeListStyle = dt([
            'lg:w-[68%]',
            'md:w-full',
            'mt-0',
            'mx-auto',
            'mb-[92px]',
            'sm:mb-[45px]',
        ]);

        const meevoListBlockStyle = dt(['block', 'text-center']);
        const btnListItemStyle = dt([
            'lg:!inline-block',
            'lg:align-middle',
            'lg:!w-[320px]',
            'md:w-[calc(50%-8px)]',
            'mdOnly:!inline-block',
            'smOnly:w-[calc(100%-8px)]',
            'my-2',
            'mx-1',
            'smOnly:text-center',
            'smOnly:!block',
        ]);
        return (
            <Layout>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <div
                    className={
                        this.isBarberyFlow() ? imageContentBlockStyle : meevoImageContentBlockStyle
                    }
                >
                    <img
                        className={imageContentStyle}
                        src={
                            this.isBarberyFlow()
                                ? url
                                : '/static-marketing/assets/static/MEVO_S+S_PAGE.png'
                        }
                        alt={this.isBarberyFlow() ? alt : 'Style + Substance'}
                        title={this.isBarberyFlow() ? title : 'Style + Substance'}
                    />
                </div>
                <div className={labelTextStyle}>
                    {this.isBarberyFlow()
                        ? labelText
                        : 'Select a location and reserve your spot today!'}
                </div>
                <div
                    className={`${buttonDivStyle} ${
                        this.isBarberyFlow() ? storeListStyle : meevoStoreListStyle
                    }`}
                >
                    <List
                        datasource={this.isBarberyFlow() ? stores : meevoStores}
                        listStyleClass={meevoListBlockStyle}
                        itemStyleClass={btnListItemStyle}
                        childRenderer={this.renderStores}
                    />
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        config: get(state, 'context.preferences.scheduleAppointmentLink'),
        stores: get(state, 'context.preferences.barberyStores', []),
        meevoStores: get(state, 'context.preferences.styleSubstanceStores', []),
        barberyService: get(state, 'context.preferences.barberyService', {}),
        meevoService: get(state, 'context.preferences.meevo.service'),
        linkData: selectAppointmentDetailsById(state, props),
        requestUrl: get(state, 'context.requestUrl', ''),
        imageContent: get(state, 'context.preferences.barberyImageContent', {}),
        authenticationSuccess: get(state, 'signInSliderDetails.authenticationSuccess'),
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(appointmentActions, dispatch),
    sliderAction: bindActionCreators(sliderActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarberyAndMeevo);
