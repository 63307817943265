export const staticSuffix = 'marketing'; // eslint-disable-line
export const micrositeName = 'yoda-microsite-mkt';
export const configServerPreferences = true; // for Preferences
export const cachePolicy = {
    version: 1,
    enabled: true,
    minCache: 7200, // Default 2 hours
    routeCache: {
        // Not supported yet
        match: '/g',
        minCache: 0,
        enabled: false,
    },
};

export const app = {
    logTag: 'MICROSITE:MARKETING',
};
export const configServerSettingsFlag = true;
export const esiHeaderRequiredRoutes = null;
export const isContextHeadersRequired = true;
