import analytics from 'yoda-core-components/lib/helpers/Analytics/Analytics';
import * as IrisActionTypes from 'yoda-site-components/lib/actionTypes/IrisActionTypes';
import * as CacheActionTypes from 'yoda-app-shell/lib/actionTypes/CacheActionTypes';
import AnalyticsActionTypes from 'yoda-site-components/lib/actionTypes/AnalyticsActionTypes';
import { logwebvitals } from 'yoda-site-components/lib/middlewares/WebvitalsMiddleware';
import { MARKETING_PAGE_LOAD } from '../actionTypes/MarketingActionTypes';
import MarketingActionFilter, {
    getShopThisLookOnClick,
    getShopTheLookMiniPDPOnClick,
} from '../analytics/MarketingActionFilter';

const marketingActionFilter = new MarketingActionFilter();

const MarketingMiddleware = store => next => action => { // eslint-disable-line
    // Setting Coupon Page Cache in here on server side.
    if (__SERVER__ && action.type === IrisActionTypes.IRIS_PAGE_SET_CACHE) {
        /* check the cacheControl and set the ttl value */
        const ttlValue = action.cacheControl || 0;
        /**
         * Dispatching Cache Action Here
         */
        store.dispatch({
            type: CacheActionTypes.SET_PAGE_CACHE,
            cache: { ttl: ttlValue },
        });
    }

    if (!__SERVER__) {
        let digitalData = null;
        let analyticsEventName = null;
        /* istanbul ignore next */
        try {
            switch (action.type) {
                case MARKETING_PAGE_LOAD: {
                    digitalData = MarketingActionFilter.getMarketingpageDL(store, action.payload);
                    digitalData = marketingActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';

                    // triggering webvitals on page load success
                    logwebvitals(store, digitalData);
                    break;
                }
                case AnalyticsActionTypes.PDP_SHOP_THIS_LOOK_CLICK_EVENT: {
                    digitalData = getShopThisLookOnClick(action.payload);
                    analyticsEventName = 'shopTheLook';
                    break;
                }

                case AnalyticsActionTypes.PDP_SHOP_THE_LOOK_MINI_PDP_CLICK_EVENT: {
                    digitalData = getShopTheLookMiniPDPOnClick(action.payload);
                    analyticsEventName = 'shopTheLookDetail';
                    break;
                }
                /* istanbul ignore next */
                default:
                    break;
            }
            if (analyticsEventName || digitalData) {
                analytics.track(analyticsEventName, digitalData);
            }
        } catch (error) {
            // @TODO - LOGGER should add here
        }
    }
    return next(action);
};

export default MarketingMiddleware;
