import siteReducers from 'yoda-site-components/lib/reducers';
import LoadingReducer from 'yoda-site-components/lib/reducers/LoadingReducer';
import AppliedAdjustmentsReducer from 'yoda-site-components/lib/reducers/AppliedAdjustmentsReducer';
import PersonalizedContentServiceReducer from 'yoda-site-components/lib/reducers/PersonalizedContentServiceReducer';
import MessageContentReducer from 'yoda-checkout-components/lib/reducers/MessageContentReducer';
import WebvitalsReducer from 'yoda-site-components/lib/reducers/WebvitalsReducer';

const rootReducer = {
    /**
     * full screen loading
     * yoda-microsite-coupon/src/containers/Pages/Coupon/Coupon.jsx
     * yoda-microsite-coupon/src/helper/MiniPDPHelper.jsx
     * yoda-microsite-homepage/src/helper/MiniPDPHelper.jsx
     * yoda-microsite-marketing/src/helper/renderMiniPDP.jsx
     * yoda-microsite-pdp/src/components/AddToCart/AddToCart.jsx
     * yoda-microsite-pdp/src/components/Bundle/BundleAddToCart/BundleAddToCart.jsx
     * yoda-microsite-pdp/src/components/GeneralInformation/GeneralInformation.jsx
     * yoda-microsite-pdp/src/components/ImageZoom/ImageZoom.jsx
     * yoda-microsite-listing/src/common/CustomAddToCart/CustomAddToCart.jsx
     */
    isLoading: LoadingReducer,
    /**
     * get applied coupon
     * yoda-site-components/src/sagas/AdjustmentSaga.js (coupon, cart, checkout, coupon drawer)
     * yoda-microsite-marketing/src/containers/Pages/Marketing/Marketing.jsx
     * Coupon drawer
     * Coupon page IRIS component "couponlist"
     */
    appliedAdjustments: AppliedAdjustmentsReducer,
    personalizedContentService: PersonalizedContentServiceReducer,
    messageContentData: MessageContentReducer,
    webvitals: WebvitalsReducer,
    ...siteReducers,
};

export default rootReducer;
