import { fork, all } from 'redux-saga/effects';
import siteComponentSaga from 'yoda-site-components/lib/sagas';
import watchPersonalizedContentServiceSaga from 'yoda-site-components/lib/sagas/PersonalizedContentServiceSaga';
import watchAddToCartInterstitialSaga from 'yoda-checkout-components/lib/sagas/AddToCartInterstitialSaga';
import watchcartItemUpdateSaga from 'yoda-checkout-components/lib/sagas/CartItemUpdateSaga';

const rootSaga = function* rootSaga() {
    yield all([
        siteComponentSaga(),
        fork(watchPersonalizedContentServiceSaga),
        fork(watchAddToCartInterstitialSaga),
        fork(watchcartItemUpdateSaga),
    ]);
};

rootSaga.injectedSagas = {
    ...siteComponentSaga.injectedSagas,
};

export default rootSaga;
